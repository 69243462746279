import Navbar from "./components/navigation/Navbar";
import Hero from "./components/hero/Hero";
import './index.css';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        
      </header>
      <Navbar />
      <Hero />
    </div>
  );
}

export default App;
