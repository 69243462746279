import React from "react";
import "../../App.css";
import "./hero.css";
import VideoHero from "../../assets/hero.mp4";
import VideoHeroVertical from "../../assets/hero_vert.mp4";
import Logo from "../../assets/logo.png";

const Hero = () => {
  return (
    <>
      <section id="hero">
        <div className="video-container">
          <video className="lg:hidden" src={VideoHeroVertical} autoPlay playsInline muted loop></video>
          <video className="hidden lg:flex" src={VideoHero} autoPlay playsInline muted loop></video>

          <div className="overlay">
            <img
              src={Logo}
              alt="Cooper Casting Logo"
              className="logo w-3/4 md:w-1/2 lg:w-1/3"
            />
            <p className="text text-justify  text-white text-xl md:text-2xl lg:text-3xl pb-5 lg:pb-10 ">
              En Cooper Casting, nos especializamos en la fabricación de
              componentes de cobre de alta calidad, adaptados a las necesidades
              específicas de cada cliente.
            </p>
            <button className="text-white rounded bg-orange-400 py-2 px-4">
              <a
                className="text-medium text-xl"
                href="mailto:a.gonzalez@ccasting.com.mx?subject=Contacto desde sitio web"
              >
                Contáctanos
              </a>
            </button>
            <div className="w-full flex justify-end">
              <p className="text-light text-right text-white italic text-xl lg:text-2xl pt-10">
                "Además de productos y servicios, entregamos soluciones."
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
